// a library to wrap and simplify api calls
import apisauce from "apisauce";

// our "constructor"
export const create = (baseURL = process.env.REACT_APP_ARTEMIS_API_URL) => {
    // ------
    // STEP 1
    // ------
    //
    // Create and configure an apisauce-based api object.
    //
    const api = apisauce.create({
        // base URL is read from the "constructor"
        baseURL,
        // here are some default headers
        headers: {
            "Cache-Control": "no-cache",
        },
        // 10 second timeout...
        timeout: 5000,
    });

    const me = () => {
        return api.post(`${process.env.REACT_APP_ARTEMIS_API_URL}/api/verifyToken`);
    };

    const signUp = (data) => {
        return api.post(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/user/register`,
            data
        );
    };

    const getNonce = (wa) => {
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/nonce/${wa}`
        );
    }

    const validateSign = (d) => {
        return api.post(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/nonce/validate`,
            d
        );
    }

    const logIn = (data) => {
        return api.post(`${process.env.REACT_APP_ARTEMIS_API_URL}/api/login`, data);
    };


    const getTwitterAuth = () => {

        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/social/twitter/auth`
        );
    }

    const searchTweetsRecent = (data) => {
        console.log(`${process.env.REACT_APP_ARTEMIS_API_URL}/api/social/twitter/search/${data}`);
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/social/twitter/search/${data}`
        );
    };

    const getCurrentTweets = (data) => {
        console.log(`${process.env.REACT_APP_ARTEMIS_API_URL}/api/social/twitter/users/tweets`);
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/social/twitter/users/tweets`
        );
    };

    const getWalletHistory = (id) => {
        console.log(`${process.env.REACT_APP_ARTEMIS_API_URL}/api/wallet/history/${id}`)
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/wallet/history/${id}`
        );
    }

    const createCollection = (data) => {
        console.log(`${process.env.REACT_APP_ARTEMIS_API_URL}/api/collection`);
        console.log(data);
        return api.post(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/collection`,
            data
        );
    };

    const createTokenList = (data) => {
       
        return api.post(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/token/list`,
            data
        );
    };

    const deleteTokenList = (data) => {
       
        return api.post(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/token/list/delete`,
            data
        );
    };

    const editTokenList = (data) => {
       
        return api.post(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/token/list/edit`,
            data
        );
    };

    const getTokenLists = (wa) => {
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/token/lists/${wa}`
        );
    }

    const getExploreLists = (wa) => {
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/token/lists/explore/${wa}`
        );
    }

    const getExploreUsers = (wa) => {
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/social/explore/${wa}`
        );
    }

    const getFollows = (wa) => {
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/user/follows/${wa}`
        );
    }

    const checkIfUsernameUnique = (data) => {
        return api.post(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/user/exists`,
            data
        );
    }

    const editFollows = (data) => {
        return api.post(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/user/follows`,
            data
        );
    }

    const getFollowedLists = (wa) => {
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/token/lists/followed/${wa}`
        );
    }

    const getAdminTokenLists = (wa) => {
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/core/admin/lists`
        );
    }

    const addTokenToList = (data) => {
        return api.post(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/token/list/add`,
            data
        );
    }

    const removeTokenFromList = (data) => {
        return api.post(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/token/list/remove`,
            data
        );
    }

    const saveCollection = (data) => {
        return api.post(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/collection/${data._id}`,
            data
        );
    };

    const getCollections = (data) => {

        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/collection/wa/${data.wa}`
        );
    }

    const getUser = (data) => {

        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/user/wa/${data.wa}`
        );
    }
    const updateUser = (data) => {

        return api.post(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/user/update`,
            data
        );
    }

    const getUserPage = (un) => {
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/social/un/${un}`
        );
    }

    const searchSocial = (data) => {
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/social/search/${data}`
        );
    }

    const addTwitterUsers = (data) => {
        return api.post(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/social/twitter/users/add`,
            data
        );
    }


    const getTwitterUsers = (data) => {
        return api.post(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/social/twitter/users`,
            data
        );
    }

    const getTwitterUserLists = (wa) => {
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/social/lists/${wa}`
        );
    }

    const addTwitterUserToList = (data) => {
        return api.post(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/social/list/add`,
            data
        );
    }

    const removeTwitterUserFromList = (data) => {
        return api.post(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/social/list/remove`,
            data
        );
    }

    const updateTwitterUserList = (data) => {
        return api.post(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/social/list/update`,
            data
        );
    }

    const getTwitterUserByUsername = (data) => {
        return api.post(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/social/twitter/user`,
            data
        );
    }

    const getTwitterPostConversation = (id) => {
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/social/twitter/conversation/${id}`
        );
    }

    const getTwitterPostsByTag = (tag, type) => {
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/social/twitter/posts/tag/${type}/${tag}`
        );
    }

    const getTwitterUsersByList = (slug) => {
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/social/list/${slug}`
        );
    }

    const searchTwitterUsers = (data) => {
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/social/twitter/users/search/${data}`
        );
    };

    const getPairsByPairAddress = (data) => {
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/pairs/${data}`,
            data
        );
    }

    const getPairsByTokenAddress = (data) => {
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/pairs/t/${data}`,
            data
        );
    }

    const getNewPairs = (data) => {
        return api.post(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/pairs/new`,
            data
        );
    }

    const getTickerPairs = () => {
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/pairs/ticker`
        );
    }

    const getEthData = (data) => {
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/core/data`
        );
    }


    const getCoreHomeMeta = (wa) => {
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/core/homemeta/${wa}`
        );
    }


    const getPairsQuery= (q) => {
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/pairs/q/${q}`
        );
    }

    const getTokenByContract = (data) => {
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/token/${data}`
        );
    }

    const getTokenByPair = (data) => {
        
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/pair/${data.chain}/${data.pa}`
        );
    }

    const getTokenByPairUpdate = (data) => {
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/pair/${data.chain}/${data.pa}`
        );
    }

    const getPortfolioTokens = (wa) => {
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/token/portfolio/${wa}`
        );
    }

    const searchPairs = (s) => {
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/pairs/search/${s}`
        );
    }

    const getTokenList = (data) => {
        return api.get(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/token/list/${data.slug}/${data.wa}`
        );
    }

    const duplicateTokenList = (data) => {
       
        return api.post(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/token/list/duplicate`,
            data
        );
    };

    const updateAdmin = (data) => {
       
        return api.post(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/core/admin`,
            data
        );
    };

    const uploadImageToCloud = (data) => {
        return api.post(
            `${process.env.REACT_APP_ARTEMIS_API_URL}/api/core/upload`,
            data
        );
    }


    // return functions for interface
    return {
        me,
        logIn,
        getNonce,
        validateSign,
        api,
        getCoreHomeMeta,
        getTwitterAuth,
        searchTweetsRecent,
        getCurrentTweets,
        getWalletHistory,
        createCollection,
        saveCollection,
        getCollections,
        checkIfUsernameUnique,
        getUser,
        updateUser,
        getUserPage,
        searchTwitterUsers,
        addTwitterUsers,
        getTwitterUsersByList,
        getTwitterUserByUsername,
        getTwitterUserLists,
        addTwitterUserToList,
        removeTwitterUserFromList,
        updateTwitterUserList,
        getTwitterPostConversation,
        getEthData,
        getPairsByPairAddress,
        getPairsByTokenAddress,
        getNewPairs,
        getTickerPairs,
        getPairsQuery,
        createTokenList,
        deleteTokenList,
        editTokenList,
        duplicateTokenList,
        getTokenLists,
        getExploreLists,
        getExploreUsers,
        getAdminTokenLists,
        addTokenToList,
        removeTokenFromList,
        searchSocial,
        editFollows,
        getFollows,
        getFollowedLists,
        getTokenByContract,
        getTokenByPair,
        getTokenByPairUpdate,
        getPortfolioTokens,
        getTokenList,
        getTwitterPostsByTag,
        searchPairs,
        updateAdmin,
        uploadImageToCloud
    };
};

// Creates a singleton
export const client = create();


// is isHoneypot
//   https://aywt3wreda.execute-api.eu-west-1.amazonaws.com/default/isHoneypot?chain=eth&token=0x1576c54D465dBD9ce621776fb42f2a19ac66A7eD
