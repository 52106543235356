import React, { Component, useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    Redirect,
    useNavigate,
    useLocation
} from "react-router-dom";

import axios from 'axios';

import { LoaderSpinner } from "./components/Layout/LoaderSpinner";

import {
  isMobile,
  isIOS,
  isChrome,
  isFirefox,
  isSafari,
  isIE,
  isEdge,
  browserVersion,
} from 'react-device-detect';

import { toastConfig } from 'react-simple-toasts';


import Draggable from 'react-draggable';


import classNames from "classnames";

import { AppLobby } from "./pages/AppLobby";

import { Website } from "./pages/Website";
import { Dashboard } from "./pages/Dashboard";
import { Portfolio } from "./pages/Portfolio";
import { WatchLists } from "./pages/WatchLists";
import { Settings } from "./pages/Settings";
import { Admin } from "./pages/Admin";
// import { MarketOverview } from "./pages/MarketOverview";
// import { Exchange } from "./pages/Exchange";
import { DexChart } from "./pages/DexChart";

import { ContentDashboard } from "./pages/ContentDashboard";

import { TokenDashboard } from "./pages/TokenDashboard";
import { Social } from "./pages/Social";


import { SocketDexTunnel } from "./components/Dex/SocketDexTunnel";

import { DexSwapDraggable } from "./components/Dex/DexSwapDraggable";

import { Collections } from "./pages/Collections";
// import { Experiments } from "./pages/Experiments";

import { NFTPortal } from "./pages/NFTPortal";

import { ModalCore } from "./components/Modal/ModalCore";
import { PhotoModal } from "./components/Modal/PhotoModal";
import { CollectionOptionsCard } from "./components/Collection/CollectionOptionsCard";

import { TokenToolbarDraggable } from "./components/Dex/TokenToolbarDraggable";
import { Layout } from "./components/Layout";
import { WalletConnectComponent } from "./components/WalletConnectComponent";

import { Notifications } from "./components/Notifications";

import ReactTooltip from 'react-tooltip';

//
// import { NotFound } from "./pages/NotFound/NotFound";
// import { PrivateRoute } from "./components/PrivateRoute";
//

import { useAuth } from "./hooks/useAuth";
import { useNavigator } from "./hooks/useNavigator";
import { useCore } from "./hooks/useCore";
import { useCollection } from "./hooks/useCollection";
import { useDex } from "./hooks/useDex";
import { useWallet } from "./hooks/useWallet";

import spinner from "./images/loader-tail-spin.svg";

import "./scss/styles.scss";

// import TimeAgo from 'javascript-time-ago'
//
// import en from 'javascript-time-ago/locale/en.json'
// import ru from 'javascript-time-ago/locale/ru.json'
//
// TimeAgo.addDefaultLocale(en)
// TimeAgo.addLocale(ru)
//

import ReactGA from 'react-ga';
// import { userData } from "three/webgpu";

// Check if we are in production mode
if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  console.warn = () => {}; // Disable console.warn
  console.error = () => {}; // Disable console.error
  console.log = () => {}; // Optionally disable console.log
}



export default function App() {
 
    let { loading } = useAuth();

    let { screens } = useNavigator();

    let {
        walletModalVisible,
        windowDimensions,
        handleWindowDimensions,
        notifications,
        fullscreen,
        showNotifications,
        toggleShowNotifications,
        contentLoaded
    } = useCore();

    let { showCollectionsOptions, cox, coy } = useCollection();
    const {
        token,
        swapVisible,
        dexChartVisible
    } = useDex();
    const {
        walletAddress,
        inApp,
        handleInApp,
        accessAllowed,
        user
    } = useWallet();

    const detectSize = () => {
      handleWindowDimensions({
        width: window.outerWidth,
        height: window.innerHeight,
      })
    }


    const location = useLocation();
  
    useEffect(() => {
      if (process.env.NODE_ENV === 'production') {
        let path = `${window.location.hostname}${window.location.pathname}${window.location.search}`
        ReactGA.pageview(path);
        console.log('page view change')
      }
    }, [screens]);


    useEffect(() => {
        handleInApp(true);

        if (process.env.NODE_ENV === 'production') {
            // Initialize Google Analytics
            
            ReactGA.pageview(window.location.hostname + window.location.pathname + window.location.search);
          
        }

          

    }, []);

    useEffect(() => {
      window.addEventListener('resize', detectSize)

      return () => {
        window.removeEventListener('resize', detectSize)
      }
  }, [windowDimensions]);




    useEffect(() => {
        if(isMobile){
            document.body.classList.add('mobile');
        }
        if(isIOS){
            document.body.classList.add('ios');
        }
        if(isChrome){
            document.body.classList.add('chrome');
        }


        toastConfig({
          time: 3333,
          className: 'toast-message',
          clickClosable: true
        });

        // getIP();

    }, []);

    const getIP = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        console.log(res.data);
        // setIP(res.data.IPv4)
    }

    const noFreeTiers = false;

    return loading ? (
        <LoaderSpinner/>
    ) : !accessAllowed && noFreeTiers ? (
        <>
            { walletModalVisible && (
                <WalletConnectComponent/>
            )}
            
            <AppLobby/>

            <ModalCore/>
        </>

    ) : (
        <>
            { walletModalVisible && (
                <WalletConnectComponent/>
            )}

            {inApp && (
                <SocketDexTunnel/>
            )}

            { walletAddress && user && user.notificationsOn ? (
                <Notifications/>
            ):null}
            
           
            { walletAddress && user && user.notificationsOn ? (
                <div onClick={toggleShowNotifications} style={{position:"absolute",zIndex:37,bottom: 5, right: 11}}>
                    <div className="coin-links">

                        { notifications.length > 0 ? (
                            <div style={{position:"absolute",left: -4,top:-4}}>
                                <div className="count-circle">
                                    {notifications.length}
                                </div>
                            </div>
                        ):null}
                        
                        
                        <div className="coin-link full">
                            { showNotifications ? (
                                <div className="icon-button-bell"/>
                            ):(
                                <div className="icon-button-bell-off"/>
                            )}
                            
                        </div>
                    </div>
                    
                </div>
            ):null}
            {!contentLoaded ? (
                <div style={{
                    background: "radial-gradient(#151515, #080808)",
                    position:'absolute',
                    width:'100vw',
                    height:'100vh',
                    top:0,
                    right:0,
                    bottom:0,
                    left:0,
                    zIndex:9999
                }}>
    
                </div>
            ):null}
            


            { showCollectionsOptions && (
                <CollectionOptionsCard/>
            )}


            { swapVisible && (

				<DexSwapDraggable token={token}/>

			)}

{/*
            <TokenToolbarDraggable/>
*/}
            <ReactTooltip
            effect="solid"
            className="tooltip"
            delayShow={300}
            delayUpdate={0}
            />

            <PhotoModal/>
            <ModalCore/>
            <Routes>
                {/* Sign In */}

                {/*<Route path="/market" element={<MarketOverview/>}></Route>*/}
                <Route path="/dashboard" element={<Dashboard/>}></Route>

                <Route path="/settings" element={<Settings/>}></Route>
                <Route path="/admin" element={<Admin/>}></Route>

                <Route path="/chart" element={<DexChart/>}></Route>

                {/*<Route path="/exchange" element={<Exchange/>}></Route>*/}
                
                <Route path="/assets/*" element={<NFTPortal/>}></Route>

                <Route path="/collections/*" element={<Collections/>}></Route>

                {/*<Route path="/experiments/*" element={<Experiments/>}></Route>*/}

                <Route path="/social/*" element={<Social/>}></Route>

                {/* <Route path="/portfolio/*" element={<Layout><Portfolio/></Layout>}></Route> */}
                <Route path="/portfolio/*" element={<ContentDashboard/>}></Route>
                <Route path="/trending" element={<ContentDashboard/>}></Route>
                <Route path="/trending/*" element={<ContentDashboard/>}></Route>
                {/* <Route path="/newpairs" element={<ContentDashboard/>}></Route> */}
                <Route path="/calls" element={<ContentDashboard/>}></Route>
                <Route path="/news" element={<ContentDashboard/>}></Route>
                <Route path="/list/*" element={<ContentDashboard/>}></Route>
                <Route path="/spaces/*" element={<ContentDashboard/>}></Route>
                <Route path="/lists" element={<ContentDashboard/>}></Route>

                <Route path="/" element={<ContentDashboard/>}></Route>

                <Route path="*" element={<ContentDashboard/>}></Route>

                {/*
                
                <PrivateRoute path="/*">
                    <NotFound />
                </PrivateRoute>

                */}

            </Routes>
         


        </>
    );
}
